import React from 'react';
import "./Ertragsrechner.css";

class ErtragsrechnerWidget extends React.Component {
   render(){
      return (
         <div className='iframe'>
<iframe src="../../Ertragsrechner.html" style={{ width: '100%', height: '100vh', border: 'none', background: '#28342E', borderRadius: '0px' }}></iframe>

         </div>
      );
   }
}

export default ErtragsrechnerWidget;


